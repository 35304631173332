import { SafariObject } from '../../base-object/models/app/safari-object-base';
import { TransferDialogOptions } from '../../redux/file-operation';

export class FileObject extends SafariObject {
  file: File;
  additionalInfo?: any;
  // additional form values that will be sent with the file
  formValues?: Map<string, string>;
  shouldUseTus?: boolean;
}
export type FileObjectForTransferDialog = FileObject & { transferDialogOptions?: TransferDialogOptions };
export const INVALID_FILE_CHARACTERS = '["<>|:*?\\/]';
export const INVALID_FILE_CHARACTERS_MESSAGE = 'Name cannot contain ' + INVALID_FILE_CHARACTERS.replace('[', '').replace(']', '').split('').join(' ');
